.loader {
  display: inline-block;
  width: var(--loading-width);
  height: var(--loading-height);
  border: var(--loading-stroke-width) solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: var(--blue);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}