.totContainer {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	color: var(--white);
	background: rgba(0, 0, 0, 0.562);
	z-index: 200;
	font-size: 1.5rem;
	padding: 0 15%;
}
small {
	margin: 3px 0;
}