.header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: calc(100%);
	height: 64px;
	padding: 0px 12px;
	/* box-shadow: 0 -1px 2px var(--dark); */
	z-index: 50;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--dark);
	background: var(--white);
}
.headerHeight {
	background: var(--white);
	height: 64px;
}
.homePage{
	text-decoration: none;
	color: var(--black);
	font-weight: bold;
	font-size: large;
}
.loginModal{
	display: none;
}
.logoContainer{
	display: flex;
	align-items: center;
	/* background: var(--blue);
	color: #fff; */
	color: var(--blue);
	/* background: #fff; */
	padding-right: 3px;
	border-radius: 6px;
	overflow: hidden;
	/* height: 30px; */
}

.logoIconWrapper{
	display: flex;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	/* display: none; */
	/* padding: 30px; */
	/* background: #fff; */
	/* padding: 0;
	margin: 0; */
	/* border: 3px solid var(--blue); */
}
.logoIcon{
	/* height: 24px; */
	margin: 0;
	padding: 0;
	/* box-shadow: 1px 0px 0px #fff; */
	filter: var(--svgColor);
	/* padding: 3px; */
}
