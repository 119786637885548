.more {
	/* border: 1px solid var(--secondaryColor); */
	border: none;
	background: transparent;
}
.left {
	/* border: 2px solid blue; */
	display: flex;
	flex-grow: 2;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 6px;
	/* border: 2px solid burlywood; */
}
.right {
	flex-grow: 1;
	/* border: 2px solid red; */
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}
.avatar{
	border-radius: 50%;
}
.profileHeader {
	top: 0;
	position: sticky;
	background-color: var(--white);
}
.votch {
	/* display: flex; */
	/* flex-direction: column; */
	/* justify-content: center; */
	/* align-items: center; */
	text-decoration: none;
	color: var(--dark);
	font-size: 90%;
}
.number{
	font-weight: bold;
	font-size: 120%;
}