.imgPrev {
	width: 100%;
	/* height: 24px; */
	/* filter: var(--svgColor);; */
}

.previewContainer{
	width: 40%;
	margin: 6px;
	min-width: 150px;
	min-height: 100px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.previewContainer:nth-child(2n+1){
	margin-left: 0px;
}
.retry {
	position: absolute;
	/* filter: var(--svgColor); */
	opacity: .75;
	transform: scale(2);
}
.deleteButton{
	position: absolute;
	width: 24px;
	height: 24px;
	top: 6px;
	right: 6px;
	border: none;
	background: var(--white);
	opacity: .8;
	border-radius: 50%;
}
.fadeDie {
	transition: opacity .2s, transform .1s;
	opacity: .00;
	transform: scale(.7);
	transform-origin: 100% 00%;
}
.semiVisible{
	opacity: .6;
}
.primarySvg{
	filter: var(--svgColor);
}
.imgUploaded {
	position: absolute;
	left: 0;
	bottom: 0;
	background: var(--white);
}