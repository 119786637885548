.main{
	min-height: 40vh;
}
.radioContainer{
	display: flex;
	align-items: center;
	margin: 24px 0;;
	/* justify-content: space-between; */
	/* flex-direction: column; */
	/* height: 70px; */
}

.uploadButtonContainer{
	text-align: center;
}
.thumbnailsContainer {
	display: flex;
	position: relative;
	justify-content: space-around;
	flex-wrap: wrap;
	width: 100%;
	margin: auto 0px;
}

.createContainer{
	text-align: right;
}
.uploadFileInput{
	display: none;
}
.primaryButtonOutline{
	cursor: pointer;
	padding: 12px;
	margin: 24px;
	border-radius: 6px;
	border: 2px solid var(--blue);
	color: var(--blue);
	background: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 78px;
	align-self: center;
}
.primarySvg{
	filter: var(--svgColor);
}


.createdContainer{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}