.toastbase {
	/* display: flex; */
	/* align-items: center; */
	position: fixed;
	top: -60px;
	right: 50%;
	transform: translateX(50%);
	text-align: center;
	z-index: 50;
	padding: 12px;
	margin: 12px 6px 24px;
	/* color: var(--red); */
	border-radius: 6px;
	background: var(--white);
	animation: slideIn .2s forwards;
	box-shadow: 0px 0px 5px var(--boxshadowColor);
	user-select: none;
	overflow: hidden;
}

.timerIndicator{
	position: absolute;
	bottom: 0;
	left: 0;
	border: 2px solid transparent;
	width: 100%;
	transform-origin: 0 0;
	height: 3px;
}

.toast-success {
	color: var(--green);
	border-color: var(--green);
}
.toast-error {
	color: var(--red);
	border-color: var(--red);
}
.toast-warn {
	color: rgb(131, 131, 66);
	border-color: rgb(165, 165, 58);
}
.toast-info {
	color: var(--blue);
	border-color: var(--blue);
}


@keyframes slideIn {
	to {
		top: 6px;
	}
}