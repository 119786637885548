
.cardContainer{
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 6px;
	box-shadow: 0px 3px 6px var(--boxshadowColor);
	/* padding: 3px 6px; */
	/* padding-right: 12px; */
	margin: 12px 0;
	max-width: 100%;
	overflow: hidden;
	color: var(--dark);
}
.textWrapperss {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.linkSection {
	color: var(--dark);
	text-decoration: none;
	display: flex;
	align-items: center;
	min-width: 60%;
}
.voteimg{
	margin-left: auto;
	height: 50px;
	width: auto;
}
.cardImg{
	margin-right: 12px;
	height: 50px;
}