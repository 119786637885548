
.cardContainer{
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 6px;
	box-shadow: 0px 0px 6px var(--boxshadowColor);
	padding: 12px;
	margin-top: 6px;
	max-width: 100%;
	overflow: hidden;
	text-decoration: none;
	color: var(--dark);
}

.imagesContainer{
	display: flex;
	justify-content: center;
	align-items: center;
	/* flex-direction: column; */
}
.cardImg{
	margin: 0 6px;
	height: 50px;
}