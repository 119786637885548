.login {
	/* padding: 12px 24px; */
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 60vh;
	width: 100%;
	/* border: 10px solid red !important; */
}
.loginContainer{
	margin: auto 0 36px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	/* border: 2px solid red; */
}
.textWrapper{
	padding: 12px;
	margin: 12px 6px 12px;
	color: var(--blue);
	border-radius: 6px;
	background: var(--secondaryColor);
}
.message{
	padding: 12px;
	margin: 12px 6px 12px;
	color: rgb(0, 112, 0);
	border-radius: 6px;
	background: rgba(179, 255, 182, 0.2);
}
.loadingDiv {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.submitButton{
	margin: 12px 0px;
	padding: 12px 18px;
	border-radius: 6px;
	color: var(--white);
	/* font-size: 110%; */
	border: none;
	background: var(--blue);
}