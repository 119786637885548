.btn {
	/* margin: 12px 0px; */
	padding: 6px 12px;
	border-radius: 6px;
	border: none;
	border: 2px solid transparent;
}

.pr-outline {
	border: 2px solid var(--blue);
	background: var(--white);
	color: var(--blue);
	font-weight: bold;
}

.pr-outline-trans {
	border: 2px solid var(--blue);
	background: transparent;
	color: var(--blue);
	font-weight: bold;
}

.primary {
	border: 2px solid var(--blue);
	background: var(--blue);
	color: var(--white);
}

.secondary {
	color: var(--dark);
	background: var(--white);
}

.link {
	color: var(--blue);
	background: var(--white);
}

.red {
	color: var(--red);
	background: transparent;
	border: 1px solid var(--red);
}

