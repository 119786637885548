.sbrk-container {
	width: 100%;
	/* border: 2px solid red; */
	display: flex;
	margin: 12px 0;
	background: var(--white);
}
.sbrk-s1 {
	flex-grow: 2;
	display: flex;
	justify-content: center;
	/* width: 100%; */
	/* border: 2px solid red; */
	
}
.sbrk-s2 {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* width: 100%; */
	/* border: 2px solid red; */
	
}

.u-container {
	display: flex;
	align-items: center;
	/* margin: 6px 0; */
}
.u1 {
	margin: 0 12px;
}
.u2 {
	display: flex;
	flex-direction: column;
}


.ps1 {
	display: flex;
	justify-content: space-between;
	padding: 6px 0;
	align-items: center;
	padding-right: 24px;
}
.ps2 {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	padding-top: 30px;
}