.container{
	position: relative;
	/* border: 2px solid red; */
	display: flex;
	align-items: center;
	justify-content: center;
	/* width: 45vw; */
	/* max-width: 47%; */
	opacity: .94;
	/* min-height: 30vh; */
	overflow: hidden;
	padding: -3px;
	border: 3px solid transparent;
}

.check {
	position: absolute;
	z-index: 1;
	opacity: 0;
	margin: auto;
	transform: scale(1.9);
	filter: var(--svgColor);
}

.thumbnail{
	width: 100%;
	height: auto;
	margin: auto;
	/* width: auto;
	height: 100%; */
	/* position: absolute; */
	filter: blur(15px);
}

.selected{
	border-color: var(--blue);
	opacity: 1;
}

.loading{
	border-style: dotted;
	border-color: var(--blue);
	animation: upDown .5s alternate infinite;
}
/* 
.blur{
	filter: blur(25px);
} */

@keyframes upDown {
	0%{
		opacity: .65;
	}
	100%{
		opacity: .85;
	}
}


.fadeToExistense{
	animation: fadeToExistense 1s alternate-reverse;
}

.undoBlur{
	
	animation: undoblur .25s forwards;
}

@keyframes undoblur {
	100%{
		filter: none;
	}
}

@keyframes fadeToExistense {
	100%{
		opacity: 1;
	}
}


.statsContainer{
	position: absolute;
	z-index: 20;
	margin: auto;
	background-color: rgba(0, 0, 0, .5);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* font-weight: bold; */
	font-size: 1.5rem;
	color: white;
}
.voteNumber{
	font-size: 2rem;
	line-height: 2;
}