.tabContainer {
	display: flex;
	flex-direction: column;
}
.tabHeadContainer {
	display: flex;
	justify-content: space-around;
	padding: 12px 0 0;
	/* border-bottom: 2px solid red; */
}
.tabHead {
	/* margin-bottom: -3px;	 */
	padding: 12px 12px;
	text-align: center;
	font-size: 110%;
	font-weight: bold;
	width: 30%;
	border-bottom: 2px solid transparent;
	/* border-top-left-radius: 6px;
	border-top-right-radius: 6px; */
}
.selected {
	border-bottom-color: var(--blue);
	color: var(--blue);
	/* border-color: white; */
}
.children {
	padding: 12px;
}