.pageWrapper {
	background: var(--white);
	border-radius: 6px;
	/* height: 100%;
	padding: auto; */
}
.fullHeight2{
	min-height: 95vh;
}
.ballotContainer {
	/* min-height: 70%; */
	/* height: calc(100% - 40px); */
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	
}

.err {
	min-height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0px;
	margin: 0 6px 3px;
	min-height: 80px;
	border-bottom: 1px solid var(--secondaryColor);
}
.progressbar{
	position: absolute;
	bottom: 0;
	width: 96%;
	/* margin: 0 3px; */
	height: 2px;
}
.unathorized {
	padding-top: 24px;
	padding-bottom: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.userLinkWrapper {
	display: flex;
	text-decoration: none;
	align-items: center;
	color: var(--dark);
}

.more {
	border: none;
	background: transparent;
}
.deadline {
	display: flex;
	flex-direction: column;
	margin-right: auto;
	margin-left: 6px;
}
.deadline::first-letter {
	text-transform: uppercase;
}
.avatar{
	border-radius: 50%;
	height: 7vh;
	margin: 0 12px;
}

.userinfo{
	display: flex;
	flex-direction: column;
}

.description{
	/* margin-bottom: auto; */
	padding: 12px 12px;
	text-align: center;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; 
	overflow: scroll;
	opacity: .80;
	max-height: 3rem;
	
	/* margin-top: auto; */
}

.skeletonWrapper {
	min-height: 65vh;
}

.imagesContainer{
	margin: auto 0;
	display: grid;
	grid-template-areas: "i0 i1" "i2 i3";
	padding: 0 3px;
	/* justify-content: center; */
	/* flex-direction: column; */
	/* align-items: center; */
	/* margin: auto; */
}
.imagesRow{
	display: flex;
	justify-content: space-between;
}

.stats {
	border-top: 1px solid var(--secondaryColor);
	/* border-bottom: 1px solid var(--secondaryColor); */
	padding: 6px 6px;
	margin: 6px 6px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* text-transform: capitalize; */
	/* margin-top: 3px; */
}

.imgIcon {
	/* margin-left: auto; */
	opacity: .5;
}
button.showmorebutton {
	margin: 0;
}
.voteDetails{
	text-align: center;
	margin: 12px;
	/* padding-top: 12px; */
	/* padding-bottom: 24px; */
}