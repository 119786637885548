.head {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0px 12px 24px 0px;
	/* background: #fff;
	box-shadow: 0px 1px 6px grey;
	top: 0;
	left: 0;
	right: 0;
	position: fixed; */
}
.kk {
	position: absolute;
	transform: translate(6px, 10px);
}
.ii {
	border:none;
	background-color: var(--white);
	color: var(--dark);
	border: 1px solid var(--dark);
	width: 100%;
	border-radius: 6px;
	padding: 12px;
	padding-left: 30px;
}
.img {
	width: 49%;
	height: auto;
}
.grid-base {
	/* Prevent vertical gaps */
	line-height: 1;
	-webkit-column-count: 2;
	-webkit-column-gap:   3px;
	-moz-column-count:    2;
	-moz-column-gap:      3px;
	column-count:         2;
	column-gap:           3px;  
}
.grid-item {
	/* Just in case there are inline attributes */
	width: 100% !important;
	height: auto !important;
	border: 2px solid transparent;
}
.selected {
	border-color: var(--blue) ;
}
.bottomSelect {
	position: fixed;
	background-color: var(--white);
	bottom: 40px;
	left: 0;
	right: 0;
	width: 100%;
	display: flex;
    align-items: center;
}

.chip {
	color: var(--dark);
}