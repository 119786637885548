.home {
	width: 100%;
	min-height: 95vh;
}
.noBallotsContainer {
	/* display: flex; */
	/* justify-content: center;
	align-items: center; */
	font-weight: bold;
	padding: 36px;
	text-align: center;

}
.h2{
	position: sticky;
	top: -3px;
	z-index: 20;
	color: var(--dark);
	background-color: var(--feedbg);
}
.captalize {
	text-transform: capitalize;
}
.list {
	position: relative;
	/* align-items: stretch; */
	/* justify-content: stretch; */
	padding: 12px 0px;
	background-color: var(--feedbg);
	min-height: 98vh;
}
.page {
	/* padding: 0 1px; */
	/* overflow-x: hidden; */
	border-radius: 6px !important;
	margin: 12px;
	/* border: 2px solid red;	 */
	background-color: var(--white);
}
.page > div {
	content-visibility: auto;
}