.radioContainer{
	display: flex;
	align-items: center;
	/* margin: 24px 0;; */
	/* justify-content: space-between; */
	/* flex-direction: column; */
	/* height: 70px; */
}
.radio{
	display: none;
}
.radio+label{
	padding: 3px 12px;
	cursor: pointer;
	border: 2px solid var(--blue);
	border-right-width: 0px;
}
.radio+label:first-of-type{
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
.radio+label:last-of-type{
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	border-right-width: 2px;
}
.radio:checked+label{
	background: var(--blue);
	color: var(--white);
	border-color: var(--blue);
}