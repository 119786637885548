.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 12px 0;
	background: var(--white);
}
.avatar {
	border-radius: 50%;
	height: 72px;
	width: auto;
	margin: 12px;
}
.addpht {
	position: absolute;
}
.btnContainer {
	margin-top: 24px;
	/* padding: 24px auto; */
	flex-direction: column;
	/* width: 100%; */
	display: flex;
	justify-content: space-between;
}