.baseuo {
	position: fixed;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 150;
}
.overlay{
	position: fixed;
	width: 100%;
	background: #000;
	opacity: 0;
	height: 100%;
	top: 0px;
	right: 0px;
	z-index: 100;
	animation: fadeIn .2s forwards;
}
@keyframes fadeIn {
	to {
		opacity: .6;
	}
}
.bottomSheet{
	position: fixed;
	bottom: -20px;
	right: 0px;
	left: 0;
	z-index: 200;
	width: 100%;
	display: flex;
	overflow: auto;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: var(--white);
	/* min-height: 40%; */
	border-radius: 24px;
	padding: 12px 12px 48px;
}

.slideIn{
	animation: slideIn .2s ease-out forwards;
}
.slideOut{
	animation: slideOut .2s ease-out forwards;
}

@keyframes slideIn{
	from {
		transform: translateY(270%);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes slideOut{
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(270%);
	}
}