:root{
  --h00:4rem;
  --h0:3rem;
  --h1:2rem;
  --h2:1.5rem;
  --h3:1.25rem;
  --h4:1rem;
  --h5:.875rem;
  --h6:.75rem;
  --line-height-1:1;
  --line-height-2:1.125;
  --line-height-3:1.25;
  --line-height-4:1.5;
  --caps-letter-spacing:.2em;
  --bold-font-weight:bold;
  --width-1:24rem;
  --width-2:32rem;
  --width-3:48rem;
  --width-4:64rem;
  --space-1:.5rem;
  --space-2:1rem;
  --space-3:2rem;
  --space-4:4rem;
  --z1:1;
  --z2:2;
  --z3:3;
  --z4:4;
  --border-width:1px;
  --border-radius:3px; 
}

html, body {
	/* height: 100vh;
	width: 100vw; */
	font-family: 'roboto', 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	background: var(--white);
	color: var(--dark);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.normalText {
    color: var(--light);
    text-decoration: none;
}
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
#app {
  background: var(--white);
	color: var(--dark);
	background-image: url('./assets/Spinner-1s-200px.svg');
	background-position: center center;
	background-repeat: no-repeat;
}

.mellowBorderBottom {
	border-bottom: 1px solid var(--secondaryColor);
}

/* utilites */

.h00{ font-size:4rem; font-size:var(--h00) }

.h0{ font-size:3rem; font-size:var(--h0) }

.h1{ font-size:2rem; font-size:var(--h1) }

.h2{ font-size:1.5rem; font-size:var(--h2) }

.h3{ font-size:1.25rem; font-size:var(--h3) }

.h4{ font-size:1rem; font-size:var(--h4) }

.h5{ font-size:.875rem; font-size:var(--h5) }

.h6{ font-size:.75rem; font-size:var(--h6) }



.font-family-inherit{ font-family:inherit }

.font-size-inherit{ font-size:inherit }

.text-decoration-none{ text-decoration:none }

.bold{ font-weight:bold; font-weight:var(--bold-font-weight, bold) }

.regular{ font-weight:normal }

.italic{ font-style:italic }

.caps{ text-transform:uppercase; letter-spacing:.2em; letter-spacing:var(--caps-letter-spacing); }

.left-align{ text-align:left }

.center{ text-align:center }

.right-align{ text-align:right }

.justify{ text-align:justify }

.nowrap{ white-space:nowrap }

.break-word{ word-wrap:break-word }

.line-height-1{ line-height:1; line-height:var(--line-height-1) }

.line-height-2{ line-height:1.125; line-height:var(--line-height-2) }

.line-height-3{ line-height:1.25; line-height:var(--line-height-3) }

.line-height-4{ line-height:1.5; line-height:var(--line-height-4) }

.list-style-none{ list-style:none }

.underline{ text-decoration:underline }

.truncate{
  max-width:100%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.list-reset{
  list-style:none;
  padding-left:0;
}


.inline{ display:inline }

.block{ display:block }

.inline-block{ display:inline-block }

.table{ display:table }

.table-cell{ display:table-cell }

.overflow-hidden{ overflow:hidden }

.overflow-scroll{ overflow:scroll }

.overflow-auto{ overflow:auto }

.clearfix:before,
.clearfix:after{
  content:" ";
  display:table
}

.clearfix:after{ clear:both }

.left{ float:left }

.right{ float:right }

.fit{ max-width:100% }

.max-width-1{ max-width:24rem; max-width:var(--width-1) }

.max-width-2{ max-width:32rem; max-width:var(--width-2) }

.max-width-3{ max-width:48rem; max-width:var(--width-3) }

.max-width-4{ max-width:64rem; max-width:var(--width-4) }

.border-box{ box-sizing:border-box }

.align-baseline{ vertical-align:baseline }

.align-top{ vertical-align:top }

.align-middle{ vertical-align:middle }

.align-bottom{ vertical-align:bottom }

.m0{ margin:0 }

.mt0{ margin-top:0 }

.mr0{ margin-right:0 }

.mb0{ margin-bottom:0 }

.ml0{ margin-left:0 }

.mx0{ margin-left:0; margin-right:0 }

.my0{ margin-top:0; margin-bottom:0 }

.m1{ margin:.5rem; margin:var(--space-1) }

.mt1{ margin-top:.5rem; margin-top:var(--space-1) }

.mr1{ margin-right:.5rem; margin-right:var(--space-1) }

.mb1{ margin-bottom:.5rem; margin-bottom:var(--space-1) }

.ml1{ margin-left:.5rem; margin-left:var(--space-1) }

.mx1{ margin-left:.5rem; margin-left:var(--space-1); margin-right:.5rem; margin-right:var(--space-1) }

.my1{ margin-top:.5rem; margin-top:var(--space-1); margin-bottom:.5rem; margin-bottom:var(--space-1) }

.m2{ margin:1rem; margin:var(--space-2) }

.mt2{ margin-top:1rem; margin-top:var(--space-2) }

.mr2{ margin-right:1rem; margin-right:var(--space-2) }

.mb2{ margin-bottom:1rem; margin-bottom:var(--space-2) }

.ml2{ margin-left:1rem; margin-left:var(--space-2) }

.mx2{ margin-left:1rem; margin-left:var(--space-2); margin-right:1rem; margin-right:var(--space-2) }

.my2{ margin-top:1rem; margin-top:var(--space-2); margin-bottom:1rem; margin-bottom:var(--space-2) }

.m3{ margin:2rem; margin:var(--space-3) }

.mt3{ margin-top:2rem; margin-top:var(--space-3) }

.mr3{ margin-right:2rem; margin-right:var(--space-3) }

.mb3{ margin-bottom:2rem; margin-bottom:var(--space-3) }

.ml3{ margin-left:2rem; margin-left:var(--space-3) }

.mx3{ margin-left:2rem; margin-left:var(--space-3); margin-right:2rem; margin-right:var(--space-3) }

.my3{ margin-top:2rem; margin-top:var(--space-3); margin-bottom:2rem; margin-bottom:var(--space-3) }

.m4{ margin:4rem; margin:var(--space-4) }

.mt4{ margin-top:4rem; margin-top:var(--space-4) }

.mr4{ margin-right:4rem; margin-right:var(--space-4) }

.mb4{ margin-bottom:4rem; margin-bottom:var(--space-4) }

.ml4{ margin-left:4rem; margin-left:var(--space-4) }

.mx4{ margin-left:4rem; margin-left:var(--space-4); margin-right:4rem; margin-right:var(--space-4) }

.my4{ margin-top:4rem; margin-top:var(--space-4); margin-bottom:4rem; margin-bottom:var(--space-4) }

.m-auto{ margin:auto; }

.mt-auto{ margin-top:auto }

.mr-auto{ margin-right:auto }

.mb-auto{ margin-bottom:auto }

.ml-auto{ margin-left:auto }

.mx-auto{ margin-left:auto; margin-right:auto; }

.my-auto{ margin-top:auto; margin-bottom:auto; }

.p0{ padding:0 }

.pt0{ padding-top:0 }

.pr0{ padding-right:0 }

.pb0{ padding-bottom:0 }

.pl0{ padding-left:0 }

.px0{ padding-left:0; padding-right:0 }

.py0{ padding-top:0;  padding-bottom:0 }

.p1{ padding:.5rem; padding:var(--space-1) }

.pt1{ padding-top:.5rem; padding-top:var(--space-1) }

.pr1{ padding-right:.5rem; padding-right:var(--space-1) }

.pb1{ padding-bottom:.5rem; padding-bottom:var(--space-1) }

.pl1{ padding-left:.5rem; padding-left:var(--space-1) }

.py1{ padding-top:.5rem; padding-top:var(--space-1); padding-bottom:.5rem; padding-bottom:var(--space-1) }

.px1{ padding-left:.5rem; padding-left:var(--space-1); padding-right:.5rem; padding-right:var(--space-1) }

.p2{ padding:1rem; padding:var(--space-2) }

.pt2{ padding-top:1rem; padding-top:var(--space-2) }

.pr2{ padding-right:1rem; padding-right:var(--space-2) }

.pb2{ padding-bottom:1rem; padding-bottom:var(--space-2) }

.pl2{ padding-left:1rem; padding-left:var(--space-2) }

.py2{ padding-top:1rem; padding-top:var(--space-2); padding-bottom:1rem; padding-bottom:var(--space-2) }

.px2{ padding-left:1rem; padding-left:var(--space-2); padding-right:1rem; padding-right:var(--space-2) }

.p3{ padding:2rem; padding:var(--space-3) }

.pt3{ padding-top:2rem; padding-top:var(--space-3) }

.pr3{ padding-right:2rem; padding-right:var(--space-3) }

.pb3{ padding-bottom:2rem; padding-bottom:var(--space-3) }

.pl3{ padding-left:2rem; padding-left:var(--space-3) }

.py3{ padding-top:2rem; padding-top:var(--space-3); padding-bottom:2rem; padding-bottom:var(--space-3) }

.px3{ padding-left:2rem; padding-left:var(--space-3); padding-right:2rem; padding-right:var(--space-3) }

.p4{ padding:4rem; padding:var(--space-4) }

.pt4{ padding-top:4rem; padding-top:var(--space-4) }

.pr4{ padding-right:4rem; padding-right:var(--space-4) }

.pb4{ padding-bottom:4rem; padding-bottom:var(--space-4) }

.pl4{ padding-left:4rem; padding-left:var(--space-4) }

.py4{ padding-top:4rem; padding-top:var(--space-4); padding-bottom:4rem; padding-bottom:var(--space-4) }

.px4{ padding-left:4rem; padding-left:var(--space-4); padding-right:4rem; padding-right:var(--space-4) }

.col{
  float:left;
  box-sizing:border-box;
}

.col-right{
  float:right;
  box-sizing:border-box;
}

.col-1{
  width:8.33333%;
}

.col-2{
  width:16.66667%;
}

.col-3{
  width:25%;
}

.col-4{
  width:33.33333%;
}

.col-5{
  width:41.66667%;
}

.col-6{
  width:50%;
}

.col-7{
  width:58.33333%;
}

.col-8{
  width:66.66667%;
}

.col-9{
  width:75%;
}

.col-10{
  width:83.33333%;
}

.col-11{
  width:91.66667%;
}

.col-12{
  width:100%;
}

@media (min-width: 40em){

  .sm-col{
    float:left;
    box-sizing:border-box;
  }

  .sm-col-right{
    float:right;
    box-sizing:border-box;
  }

  .sm-col-1{
    width:8.33333%;
  }

  .sm-col-2{
    width:16.66667%;
  }

  .sm-col-3{
    width:25%;
  }

  .sm-col-4{
    width:33.33333%;
  }

  .sm-col-5{
    width:41.66667%;
  }

  .sm-col-6{
    width:50%;
  }

  .sm-col-7{
    width:58.33333%;
  }

  .sm-col-8{
    width:66.66667%;
  }

  .sm-col-9{
    width:75%;
  }

  .sm-col-10{
    width:83.33333%;
  }

  .sm-col-11{
    width:91.66667%;
  }

  .sm-col-12{
    width:100%;
  }

}

@media (min-width: 52em){

  .md-col{
    float:left;
    box-sizing:border-box;
  }

  .md-col-right{
    float:right;
    box-sizing:border-box;
  }

  .md-col-1{
    width:8.33333%;
  }

  .md-col-2{
    width:16.66667%;
  }

  .md-col-3{
    width:25%;
  }

  .md-col-4{
    width:33.33333%;
  }

  .md-col-5{
    width:41.66667%;
  }

  .md-col-6{
    width:50%;
  }

  .md-col-7{
    width:58.33333%;
  }

  .md-col-8{
    width:66.66667%;
  }

  .md-col-9{
    width:75%;
  }

  .md-col-10{
    width:83.33333%;
  }

  .md-col-11{
    width:91.66667%;
  }

  .md-col-12{
    width:100%;
  }

}

@media (min-width: 64em){

  .lg-col{
    float:left;
    box-sizing:border-box;
  }

  .lg-col-right{
    float:right;
    box-sizing:border-box;
  }

  .lg-col-1{
    width:8.33333%;
  }

  .lg-col-2{
    width:16.66667%;
  }

  .lg-col-3{
    width:25%;
  }

  .lg-col-4{
    width:33.33333%;
  }

  .lg-col-5{
    width:41.66667%;
  }

  .lg-col-6{
    width:50%;
  }

  .lg-col-7{
    width:58.33333%;
  }

  .lg-col-8{
    width:66.66667%;
  }

  .lg-col-9{
    width:75%;
  }

  .lg-col-10{
    width:83.33333%;
  }

  .lg-col-11{
    width:91.66667%;
  }

  .lg-col-12{
    width:100%;
  }

}

.flex{ display:flex }

@media (min-width: 40em){
  .sm-flex{ display:flex }
}

@media (min-width: 52em){
  .md-flex{ display:flex }
}

@media (min-width: 64em){
  .lg-flex{ display:flex }
}

.flex-column{ flex-direction:column }

.flex-wrap{ flex-wrap:wrap }

.items-start{ align-items:flex-start }

.items-end{ align-items:flex-end }

.items-center{ align-items:center }

.items-baseline{ align-items:baseline }

.items-stretch{ align-items:stretch }

.self-start{ align-self:flex-start }

.self-end{ align-self:flex-end }

.self-center{ align-self:center }

.self-baseline{ align-self:baseline }

.self-stretch{ align-self:stretch }

.justify-start{ justify-content:flex-start }

.justify-end{ justify-content:flex-end }

.justify-center{ justify-content:center }

.justify-between{ justify-content:space-between }

.justify-around{ justify-content:space-around }

.justify-evenly{ justify-content:space-evenly }

.content-start{ align-content:flex-start }

.content-end{ align-content:flex-end }

.content-center{ align-content:center }

.content-between{ align-content:space-between }

.content-around{ align-content:space-around }

.content-stretch{ align-content:stretch }

.flex-auto{
  flex:1 1 auto;
  min-width:0;
  min-height:0;
}

.flex-none{ flex:none }

.order-0{ order:0 }

.order-1{ order:1 }

.order-2{ order:2 }

.order-3{ order:3 }

.order-last{ order:99999 }

.relative{ position:relative }

.absolute{ position:absolute }

.fixed{ position:fixed }

.top-0{ top:0 }

.right-0{ right:0 }

.bottom-0{ bottom:0 }

.left-0{ left:0 }

.z1{ z-index:1; z-index:var(--z1) }

.z2{ z-index:2; z-index:var(--z2) }

.z3{ z-index:3; z-index:var(--z3) }

.z4{ z-index:4; z-index:var(--z4) }

.border{
  border-style:solid;
  border-width:1px;
  border-width:var(--border-width);
}

.border-top{
  border-top-style:solid;
  border-top-width:1px;
  border-top-width:var(--border-width);
}

.border-right{
  border-right-style:solid;
  border-right-width:1px;
  border-right-width:var(--border-width);
}

.border-bottom{
  border-bottom-style:solid;
  border-bottom-width:1px;
  border-bottom-width:var(--border-width);
}

.border-left{
  border-left-style:solid;
  border-left-width:1px;
  border-left-width:var(--border-width);
}

.border-none{ border:0 }

.rounded{ border-radius:3px; border-radius:var(--border-radius) }

.circle{ border-radius:50% }

.rounded-top{ border-radius:3px 3px 0 0; border-radius:var(--border-radius) var(--border-radius) 0 0 }

.rounded-right{ border-radius:0 3px 3px 0; border-radius:0 var(--border-radius) var(--border-radius) 0 }

.rounded-bottom{ border-radius:0 0 3px 3px; border-radius:0 0 var(--border-radius) var(--border-radius) }

.rounded-left{ border-radius:3px 0 0 3px; border-radius:var(--border-radius) 0 0 var(--border-radius) }

.not-rounded{ border-radius:0 }

.hide{
  position:absolute !important;
  height:1px;
  width:1px;
  overflow:hidden;
  clip:rect(1px, 1px, 1px, 1px);
}

@media (max-width: 40em){
  .xs-hide{ display:none !important }
}

@media (min-width: 40em) and (max-width: 52em){
  .sm-hide{ display:none !important }
}

@media (min-width: 52em) and (max-width: 64em){
  .md-hide{ display:none !important }
}

@media (min-width: 64em){
  .lg-hide{ display:none !important }
}

.display-none{ display:none !important }




/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:var(--dark);
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:var(--dark);
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;  
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
    font-family: inherit;
}
code {
    background-color: var(--secondaryColor);
    padding: 3px 6px;
    border-radius: 3px;
}