.react-tag-input {
    color: var(--black) !important;
	background-color: var(--white) !important;
}
.react-tag-input__tag {
    background-color: var(--black) !important;
    border: 1px solid #777 !important;
}
.react-tag-input__tag__content {
	color: var(--black) !important;
}
.react-tag-input__input {
    color: var(--black) !important;
}