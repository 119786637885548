
.wrapper{
    display: flex;
    align-items: center;
    position: relative;
    /* justify-content: center; */
    min-width: 300px;
    width: 100%;
    margin: 6px 0;
    /* border: 2px solid red; */
}
.valid {
    /* border: 1px solid green; */
    color: var(--green);
}
.label {
    position: absolute;
    margin-right: 12px;
    text-transform: capitalize;
    transition: transform .2s;
    transform: translate(6px, 50%);
}
.input:focus {
    /* border-color: var(--blue); */
    /* transform: translate(-50%, -100%); */
}
.float, .input:focus+.label, .input:-webkit-autofill+.label {
    color: var(--blue);
    transform: translate(-1%, -150%);
}
.checkmark {
    position: absolute;
    right: 6px;
    transform: translateY(9px) scale(.7);
    transform-origin: 50% center;
    /* top: -3px; */
}
.input {
    border: none;
    width: 100%;
    background: transparent;
    margin-top: 18px;
    color: var(--dark);
    border: 1px solid var(--blue);
    border-radius: 6px;
    padding: 12px;
}
.loading {
    /* margin-left: auto; */
    animation: spin .15s infinite;
}
.error {
    /* opacity: .5; */
    /* border: 1px solid red; */
    color: var(--red);
}
.inputerror {
    border: 1px solid red;
    /* opacity: .5; */
    /* border: 1px solid red; */
    /* color: var(--red); */
}
@keyframes spin {
    to {
        transform: translate(-6px, 6px) rotate(-180deg);
    }
}