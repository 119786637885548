.footer {
	position: sticky;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 60px;
	padding: 0;
	background: var(--white);
	box-shadow: 0 0 5px var(--boxshadowColor);
	z-index: 50;
}

.badge {
	position: absolute;
	/* border: 2px solid red; */
	/* border-radius: 50%; */
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	top: -6px;
	right:12px;
}

.footer nav {
	display: flex;
	margin: 0 auto;
	justify-content: space-around;
	align-items: center;
	max-width: 600px;
	height: 100%;
}

.bottomNavLink {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 20%;
	-webkit-tap-highlight-color: transparent;
}
.bottomNavLink > img {
	transform: scale(1.2);
	/* filter: var(--svgDark); */
}

.bottomNavLink.active > img {
	transform: scale(1.05);
	filter: var(--svgColor);
	animation: rippleKinda .1s;
}

.fill {
	display: none;
}
.active .fill  {
	display: block;
}
.active .outl  {
	display: none;
}

@keyframes rippleKinda {
	to {
		transform: scale(.9);
		opacity: .8;
	}
}
