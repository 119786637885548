.pkij {
    padding: 0;
    padding-left: 6px;
}
.l1 {
    /* border: 2px solid red; */
    display: flex;
    position: relative;
}

.dv1 {
    position: absolute;
    bottom: 12px;
    left: 12px;
    color: white;
    text-shadow: 0px 0px 6px black;
}

.img {
    width: auto;
    max-width: 100%;
    min-height: 30vh;
    /* max-height: 30vh; */
}

.fgsdte {
    display: flex;
    padding: 6px 0;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-stop: center;
}
.hfh {
    width: 40%;
    height: auto;
    margin: 0 6px;
}