.splash {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: var(--blue);
	height: 100%;
	z-index: 250;
}
.LogoSplash {
	transform: scale(4);
}
.ppp{
	position: absolute;
	bottom: 24px;
	color: var(--white);
}
.rotating {
	animation: rt 1.5s alternate infinite;
}
@keyframes rt {
	100% {
		transform: rotate(-180deg) scale(4.75);
	}
}